<template>
  <unauthenticated-form>
    <form @submit.prevent="onSubmit">
      <BfInput
        v-model="email"
        label="Email Address"
        name="username"
        type="email"
        autofocus
      />
      <BfInput
        v-model="password"
        label="Password"
        name="password"
        type="password"
      />
      <BfAlert
        v-if="error"
        type="error"
        icon="error"
      >
        {{ error }}
      </BfAlert>
      <BfInput
        type="submit"
        value="Sign In"
      />
    </form>
    <div class="forgot-password">
      <router-link :to="{name: 'forgot-password'}">
        Forgot your password?
      </router-link>
    </div>
  </unauthenticated-form>
</template>

<script>
import Auth from '@aws-amplify/auth'

import BfInput from '@/components/BfInput/BfInput'
import BfAlert from '@/components/BfAlert/BfAlert'
import UnauthenticatedForm from '@/components/UnauthenticatedForm/UnauthenticatedForm'
import apolloUtilities from '@/mixins/apolloUtilities'

export default {
  name: 'Login',
  components: { BfInput, UnauthenticatedForm, BfAlert },
  mixins: [ apolloUtilities ],
  data() {
    return {
      email: '',
      password: '',
      error: null
    }
  },
  methods: {
    /**
     * Handles submit event
     * @param {Object} evt
     */
    async onSubmit(evt) {
      this.$store.dispatch('updateLoading', true)
      evt.preventDefault()

      try {
        await this.clearApolloCache()
        await Auth.signIn(this.email, this.password)

        const redirectPage = this.$store.state.redirectPage
        if (redirectPage) {
          this.$router.push(redirectPage)
        } else {
          this.$router.push('/')
        }
      } catch (e) {
        this.error = 'The email or password you entered is incorrect.'
      } finally {
        this.$store.dispatch('updateLoading', false)
      }
    }
  }
}
</script>

<style lang="scss">
.unauthenticated-form {

  .forgot-password {
    margin-top: 1rem;

    a {
      text-decoration: underline;
    }
  }
}
</style>
