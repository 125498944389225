<template>
  <div class="unauthenticated">
    <div class="unauthenticated-form">
      <header>
        <img
          class="logo"
          :src="logoImgSrc"
        >
        <slot name="header" />
        <BfAlert
          v-for="(flash, index) in flashMessages"
          :key="index"
          :closable="false"
          :type="flash.type"
          :icon="flash.type === 'error' ? 'warning-filled' : 'checkmark'"
        >
          {{ flash.message }}
        </BfAlert>
      </header>
      <slot />
    </div>
    <footer>
      <div class="copyright">
        <p>Copyright {{ new Date().getFullYear() }} Institute for Neurodegenerative Disorders. All rights reserved.</p>
        <p>
          <a
            href="https://indd.org/privacy-policy"
            target="_blank"
          >
            Privacy and Terms of Use</a>.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import BfAlert from '@/components/BfAlert/BfAlert'
import BlackfynnLogo from '@/assets/images/ppmi-logo-horiz.png'
import PPMILogo from '@/assets/images/ppmi_logo.jpg'

export default {
  name: 'UnauthenticatedForm',
  components: {
    BfAlert
  },
  props: {
    logo: {
      type: String,
      default: 'blackfynn',
      validator: val => ['blackfynn', 'ppmi'].includes(val)
    }
  },
  computed: {
    flashMessages() {
      return this.$store.state.flashMessageModule.flashMessages
    },
    logoImgSrc() {
      return this.logo === 'ppmi' ? PPMILogo : BlackfynnLogo
    }
  }
}
</script>

<style lang="scss">
.unauthenticated {
  background-color: white;
  width: 37rem;
  padding: 1rem 8rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .logo {
    width: 14rem;
  }

  header {
    width: 100%;
    margin-top: auto;
    margin-bottom: 6rem;

    .el-alert:last-child {
      margin-bottom: -3.5rem !important;
    }

    @media screen and (max-width: $breakpoint-tablet) {
      margin-bottom: 2rem;
    }
  }

  .bf-input.form-field {
    margin-bottom: 2rem;
  }

  .el-button,
  .el-input[type="submit"] {
    width: 100%;
    margin: 1rem 0;
  }

  form {
    margin-top: -5rem;

    @media screen and (max-width: $breakpoint-tablet) {
      margin-top: 0;
    }
  }

  footer {
    width: 100%;
    margin-top: auto;

    .copyright {
      color: $hillcock;
      @include text-style('interface', 'extra-small', 'regular');

      p {
        margin: 0;
      }

      a {
        color: $hillcock;
        text-decoration: underline;
      }
    }
  }

  .unauthenticated-form {
    width: 100%;
    margin-top: auto;

    @media screen and (max-width: $breakpoint-tablet) {
      max-width: 22rem;
      margin: auto;
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    width: 100%;
    padding: 1rem;

    .bf-alert {
      text-align: left;

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}
</style>
